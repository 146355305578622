<template>
   <RouterView />
</template>

<script>

export default {
  name: 'App',
}
</script>
